import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VeraenderungComponent } from './veraenderung/veraenderung.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import {KostenloseMeditationComponent} from './kostenlose-meditation/kostenlose-meditation.component';
import { TourComponent } from './tour/tour.component'
import { PodcastComponent } from './podcast/podcast.component';
import { FreedomComponent } from './freedom/freedom.component';
import { Freedom2Component } from './freedom2/freedom2.component';
import { LoginFreedomComponent } from './login-freedom/login-freedom.component';
import { WebinarFreedom2021Component } from './webinar-freedom2021/webinar-freedom2021.component';
import { ExperimentalComponent } from './experimental/experimental.component';


const routes: Routes = [
  {path: 'veränderung', component: VeraenderungComponent},
  {path: '', component: VeraenderungComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: 'kontakt', component: KontaktComponent},
  {path: 'gratisMeditation', component: KostenloseMeditationComponent},
  {path: 'gratismeditation', component: KostenloseMeditationComponent},
  {path: 'einführung', component: TourComponent},
  {path: 'podcast', component: PodcastComponent},
  {path: 'freedom2021', component: Freedom2Component},
  {path: 'freedom2020', component: Freedom2Component},
  {path: 'freedom2021Login', component: LoginFreedomComponent},
  {path: 'EX', component: ExperimentalComponent},
  {path: 'Freedom2021ComptWeb', component: WebinarFreedom2021Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
