import { Component, OnInit } from '@angular/core';
import{ trigger, state, style, animate, transition, query, group, keyframes, stagger } from '@angular/animations';

@Component({
  selector: 'app-freedom',
  templateUrl: './freedom.component.html',
  styleUrls: ['./freedom.component.scss'],
  animations: [
    trigger('FadeIn', [
      transition(':enter',[
        style({ opacity: 0 }),
        animate('1500ms', style({ opacity: 1 }))
      ]),
      transition(':leave',[
        style({ opacity: 1 }),
        animate('1000ms', style({ opacity: 0 }))
      ])
    ]),



  ]
})
export class FreedomComponent implements OnInit {

  wilkom: String = "#";
  status: number = 0;

  constructor() { 
      this.typingEffect() 
  }

  ngOnInit() {
    
  }

  typingEffect(){
    setTimeout(()=>{ 
      this.wilkom = "#F";
      setTimeout(()=>{ 
        this.wilkom = "#Fr";
        setTimeout(()=>{ 
          this.wilkom = "#Fre";
          setTimeout(()=>{ 
            this.wilkom = "#Free";
            setTimeout(()=>{ 
              this.wilkom = "#Freed";
              setTimeout(()=>{ 
                this.wilkom = "#Freedo";
                setTimeout(()=>{ 
                  this.wilkom = "#Freedom";
                  setTimeout(()=>{
                  this.status=1;
                  }, 3000)
                }, 200);
              }, 200);
            }, 200);
          }, 200);
        }, 200);
      }, 200);
    }, 200);
  }

  scrollen($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
