import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ParticlesModule } from 'angular-particle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnapscrollModule } from 'angular-snapscroll';




import { HttpModule } from '@angular/http';
import { HttpClientModule }    from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VeraenderungComponent } from './veraenderung/veraenderung.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { KostenloseMeditationComponent } from './kostenlose-meditation/kostenlose-meditation.component';
import { TourComponent } from './tour/tour.component';
import { PodcastComponent } from './podcast/podcast.component';
import { FreedomComponent } from './freedom/freedom.component';
import { Freedom2Component } from './freedom2/freedom2.component';
import { LoginFreedomComponent } from './login-freedom/login-freedom.component';
import { WebinarFreedom2021Component } from './webinar-freedom2021/webinar-freedom2021.component';
import { ExperimentalComponent } from './experimental/experimental.component';




@NgModule({
  declarations: [
    AppComponent,
    VeraenderungComponent,
    HomeComponent,
    ImpressumComponent,
    DatenschutzComponent,
    KontaktComponent,
    KostenloseMeditationComponent,
    TourComponent,
    PodcastComponent,
    FreedomComponent,
    Freedom2Component,
    LoginFreedomComponent,
    WebinarFreedom2021Component,
    ExperimentalComponent,
  ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ParticlesModule,
    BrowserAnimationsModule,
  ],
  providers: [ CookieService ],
  bootstrap: [AppComponent]
})
export class AppModule { }

