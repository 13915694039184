import { Component, OnInit } from '@angular/core';
import{ trigger, state, style, animate, transition, query, group, keyframes, stagger } from '@angular/animations';
import {Howl, Howler} from 'howler';
import * as $ from 'jquery';
import { NgIf } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  
})
export class HomeComponent implements OnInit {

  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;
  schluessel = true;

constructor() { 
  
}

  ngOnInit(){
    function changeCss () {
      var bodyElement = document.querySelector("body");
      var navElement = document.querySelector("nav");
      //this.scrollY > 500 ? Textbanner.style.opacity = 1 : Textbanner.style.opacity = .8;
 
      //Textbanner.style.background = "linear-gradient(135deg, rgb(45, 45, 90), black 55%, rgb(12, 55, 95))";
    }
    
    window.addEventListener("scroll", changeCss , false);
  

  this.myStyle = {
    'position': 'fixed',
    'width': '100%',
    'height': '10%',
    'z-index': 1,
    'top': 0,
    'left': '0',
    'right': 0,
    'bottom': 0,
    
};


this.myParams = { //blue
  particles: {
    number: { value: 100, density: { enable: true, value_area: 800 } },
    color: { value: "#ffffff" },
    shape: {
      type: "circle",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "img/github.svg", width: 100, height: 100 }
    },
    opacity: {
      value: 1,
      random: true,
      anim: { enable: true, speed: 1, opacity_min: 0.5, sync: false }
    },
    size: {
      value: 2,
      random: true,
      anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
    },
    line_linked: {
      enable: false,
      distance: 48.10236182596568,
      color: "#ffffff",
      opacity: 0.4,
      width: 1
    },
    move: {
      enable: true,
      speed: 2,
      direction: "none",
      random: true,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 1200 }
    }
  },
  interactivity: {
    detect_on: "window",
    events: {
      onhover: { enable: true, mode: "repulse" },
      onclick: { enable: false, mode: "push" },
      resize: true
    },
    modes: {
      grab: { distance: 400, line_linked: { opacity: 1 } },
      bubble: { distance: 400, size: 10, duration: 2, opacity: 8, speed: 3 },
      repulse: { distance: 97.44926547616141, duration: 0.4 },
      push: { particles_nb: 4 },
      remove: { particles_nb: 2 }
    }
  },
  }  
  }

  Einsammeln(){
    this.schluessel=false;
  }

}

  
  