import { Component, OnInit } from '@angular/core';
import{
  ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service'; 


@Component({
  selector: 'app-kostenlose-meditation',
  templateUrl: './kostenlose-meditation.component.html',
  styleUrls: ['./kostenlose-meditation.component.scss']
})
export class KostenloseMeditationComponent implements OnInit {

  Erfolg: String;
  Hat: boolean;
  Neu: boolean;
  ReturnVal: string;
  IntVal: number;

  Ueberschrift: String;
  Nachricht: boolean;

  constructor(public http: HttpClient, private cookieService: CookieService) {
    this.Erfolg = "";
    this.Nachricht = false;
    this.Hat = false;
    
    

    if(this.cookieService.check('n')){
      this.Neu = false;
      this.Vorname = this.cookieService.get('un');


      this.ReturnVal = this.cookieService.get('n');
      this.IntVal = +this.ReturnVal;
      this.IntVal++;

      if(this.IntVal>0){
        this.Ueberschrift=", willkommen zurück";
        this.cookieService.set('n', this.IntVal.toString());
        this.Nachricht = true;
      }

      
    } else {this.Neu = true;}
    
    
   }

  ngOnInit() {
    
  }

  Vorname: string;
  Nachname: String;
  Geburtsdatum: String;
  EMail: String;
  Stoert: String;
  Werbung: String;

  

 

  senden(){
    if(this.Hat==false){
    this.Erfolg="Du wirst jetzt zu meiner kostenlosen Meditaion weitergeleitet";
    let user = {
      type: "Zugriff auf kostenlose Meditation",
      Vorname: this.Vorname,
      Nachname: this.Nachname,
      Geburtsdatum: this.Geburtsdatum,
      EMail: this.EMail,
      Stoert: this.Stoert,
      Werbung: this.Werbung
    }
    
    this.sendMail('https://potenzial-liebe-seele.de/server/sendmail', user).subscribe(
      data => {
        let res:any = data;
        console.log("ok");
      }
    ) 
    this.Hat = true;
  
  }else{
    alert("Du kannst nur eine Nachricht auf einmal verschicken.")
  }

  //warten
  this.cookieService.set('n','1');
  this.cookieService.set('un', this.Vorname);
  this.Ueberschrift=", viel Spaß mit dieser Kostenlosen Meditation!";
  this.Neu=false;
}

  sendMail(url, data) {
    return this.http.post(url, data, {responseType: 'text'}); 
  }
}
