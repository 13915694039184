import { Component, OnInit } from '@angular/core';
import{
  ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import {Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service'; 

@Component({
  selector: 'app-login-freedom',
  templateUrl: './login-freedom.component.html',
  styleUrls: ['./login-freedom.component.scss']
})
export class LoginFreedomComponent implements OnInit {

  constructor(public http: HttpClient, private router:Router, private cookieService: CookieService) { 
    
  }

  ngOnInit() {
    if(this.cookieService.check('Freedom2021xLogin')){
      if(!this.cookieService.check('NoLoginRedirect')){
        this.router.navigate(['/Freedom2021ComptWeb']);
      } 
  }
  }

  Gutscheincode: String;
  Vorname: String;
  EMail: String;
  Werbung: String;

  senden(){
        if(this.Gutscheincode=="Freedom100"||this.Gutscheincode=="freedom100"){
      let user = {
            type: "Regestrierung ",
            Vorname: this.Vorname,
            EMail: this.EMail,
            Werbung: this.Werbung
          }
          this.sendMail('https://potenzial-liebe-seele.de/server/sendmail', user).subscribe(
            data => {
              let res:any = data;
              console.log("ok");
            }
          )  
          this.cookieService.set('Freedom2021xLogin', "xbaOK");
          setTimeout(() => {  
            this.router.navigate(['/Freedom2021ComptWeb']);
          }, 700);
    }
    else{
      setTimeout(() => {  
        this.router.navigate(['freedom2021']);
      }, 700);
    }
     
}

  sendMail(url, data) {
    return this.http.post(url, data, {responseType: 'text'}); 
  }

}
