import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service'; 
import {Router} from '@angular/router';

@Component({
  selector: 'app-webinar-freedom2021',
  templateUrl: './webinar-freedom2021.component.html',
  styleUrls: ['./webinar-freedom2021.component.scss']
})
export class WebinarFreedom2021Component implements OnInit {

  constructor(private cookieService: CookieService, private router:Router) {
    
  }

  ngOnInit() {
    /*
    if(!this.cookieService.check('Freedom2021xLogin')){
      this.router.navigate(['freedom2021']);
    }
    */
  }

  Uebersicht(){
    if(!this.cookieService.check('NoLoginRedirect')){
     this.cookieService.set('NoLoginRedirect', "xbaOK"); 
    }
  this.router.navigate(['freedom2021']);
  }

  scrollen($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
