import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service'; 
import {Router} from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-experimental',
  templateUrl: './experimental.component.html',
  styleUrls: ['./experimental.component.scss']
})
export class ExperimentalComponent implements OnInit {

  wilkom: String = "#";
  status: number = 0;
  popUpBool: boolean = false;
  angemeldet: boolean = false;

  constructor(private cookieService: CookieService, private router:Router, public http: HttpClient) {
    this.popUpBool=false;
    this.typingEffect();
   }

  ngOnInit() {
    if(this.cookieService.check('Freedom2021xLogin')){
      if(!this.cookieService.check('NoLoginRedirect')){
        this.router.navigate(['/Freedom2021ComptWeb']);
      } 
  }
  }

  Gutscheincode: String;
  Vorname: String;
  EMail: String;
  Werbung: String;

  senden(){
      let user = {
            type: "Registrierung (Warteliste)",
            Vorname: this.Vorname,
            Nachname: "",
            Geburtsdatum: "",
            EMail: this.EMail,
            Werbung: this.Werbung,
            Stoert: ""      
      
      

          }
          this.sendMail('https://potenzial-liebe-seele.de/server/sendmail', user).subscribe(
            data => {
              let res:any = data;
              console.log("ok");
            }
          ) 
          
          setTimeout(()=>{
            this.angemeldet=true;
          }, 400);
          
}

  sendMail(url, data) {
    return this.http.post(url, data, {responseType: 'text'}); 
  }

  popUp(){
    //this.router.navigate(['/freedom2021Login']);
    if(this.cookieService.check('Freedom2021xLogin')){
      this.router.navigate(['/Freedom2021ComptWeb']);
    } else {
      this.popUpBool=true;
    }
  }
  popUpZu(){
    this.popUpBool=false;
  }

  typingEffect(){
    setTimeout(()=>{ 
      this.wilkom = "#F";
      setTimeout(()=>{ 
        this.wilkom = "#Fr";
        setTimeout(()=>{ 
          this.wilkom = "#Fre";
          setTimeout(()=>{ 
            this.wilkom = "#Free";
            setTimeout(()=>{ 
              this.wilkom = "#Freed";
              setTimeout(()=>{ 
                this.wilkom = "#Freedo";
                setTimeout(()=>{ 
                  this.wilkom = "#Freedom";
                  setTimeout(()=>{
                  this.status=1;
                  }, 3000)
                }, 200);
              }, 200);
            }, 200);
          }, 200);
        }, 200);
      }, 200);
    }, 200);
  }

  scrollen($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
