import { Component, OnInit } from '@angular/core';
import{
  ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  Vorname: String;
  Nachname: String;
  EMail: String;
  Nachricht: String;

  constructor(public http: HttpClient) { }

  ngOnInit() {
  }

  senden(){
    alert("Diese Funktion funktioniert aktuell noch nicht. Schreibe mit bitte ein EMail an info@potenzial-liebe-seele.de")
    let nachricht = {
      type: "Nachricht",
      Vorname: this.Vorname,
      Nachmame: this.Nachname,
      EMail: this.EMail,
      Nachricht: this.Nachricht
    }
    this.sendMail('https://potenzial-liebe-seele.de/server/nachricht', nachricht).subscribe(
      data => {
        let res:any = data;
        console.log("ok");
      }
    )
  }

  sendMail(url, data) {
    return this.http.post(url, data, {responseType: 'text'}); 
  }

}
