import { Component, OnInit } from '@angular/core';
import{
  ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';
import { HttpClient } from "@angular/common/http";







@Component({
  selector: 'app-veraenderung',
  templateUrl: './veraenderung.component.html',
  styleUrls: ['./veraenderung.component.scss']
})

export class VeraenderungComponent implements OnInit {

  Erfolg: String;
  Hat: boolean;

  constructor(public http: HttpClient) {
    this.Erfolg = "";
    this.Hat = false;
  }

  ngOnInit() {}

  Vorname: String;
  Nachname: String;
  Geburtsdatum: String;
  EMail: String;
  ZuErreichen: String;
  Stoert: String;
  Werbung: String;

 

  senden(){
    if(this.Hat==false){
    this.Erfolg="Deine Nachricht wurde erfolgreich versendet";
    let user = {
      type: "Anfrage",
      Vorname: this.Vorname,
      Nachname: this.Nachname,
      Geburtsdatum: this.Geburtsdatum,
      EMail: this.EMail,
      Stoert: this.Stoert,
      Werbung: this.Werbung
    }
    this.sendMail('https://potenzial-liebe-seele.de/server/sendmail', user).subscribe(
      data => {
        let res:any = data;
        console.log("ok");
      }
    ) 
    this.Hat = true;   
  }else{
    alert("Du kannst nur eine Nachricht auf einmal verschicken.")
  }
}

  sendMail(url, data) {
    return this.http.post(url, data, {responseType: 'text'}); 
  }

  scrollen($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }


}
