import { Component, OnInit } from '@angular/core';
import{ trigger, state, style, animate, transition, query, group, keyframes, stagger } from '@angular/animations';
import {Howl, Howler} from 'howler';
import * as $ from 'jquery';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
  animations: [
    trigger('Textladen', [
      transition(':enter',[
        style({ 'transform': 'scale(0.8)' }),
        animate('20000ms', style({ 'transform': 'scale(1)' }))
      ])
    ]),

    trigger('FadeIn', [
      transition(':enter',[
        style({ opacity: 0 }),
        animate('2000ms', style({ opacity: 1 }))
      ]),
      transition(':leave',[
        style({ opacity: 1 }),
        animate('1000ms', style({ opacity: 0 }))
      ])
    ]),

    trigger('FadeIn2', [
      transition(':enter',[
        style({ opacity: 0, }),
        animate('2000ms', style({ opacity: 1, }))
      ]),
      transition(':leave',[
        style({ transform: 'scale(1)' }),
        animate('90ms', style({ transform: 'scale(0.99)' }))
      ])
    ]),

    trigger('LongFade', [
      state('0', style({
        opacity: 0
      })),
      state('1', style({
        opacity: 1
      })),
      transition('* => 0', [
        animate('0ms')
      ]),
      transition('0 => 1', [
        animate('1000ms')
      ])
    ]),

    trigger('vonOben', [
      transition(':enter',[
        style({ transform: 'scale(0)' }),
        animate('100ms', style({ transform: 'scale(0.7)' }))
      ]),
      transition(':leave',[
        style({ transform: 'scale(0.7)' }),
        animate('90ms', style({ transform: 'scale(0)' }))
      ])
    ]),

    

    trigger('backchange1', [
      state('1', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 0
      })),
      state('3', style({
        opacity: 0
      })),
      state('4', style({
        opacity: 0
      })),
      state('5', style({
        opacity: 0
      })),
      state('5.3', style({
        opacity: 0
      })),
      state('5.6', style({
        opacity: 0
      })),
      transition('* <=> *', [
        animate('5000ms')
      ])
    ]),

    trigger('backchange2', [
      state('*', style({
        opacity: 0
      })),
      state('1', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 1
      })),
      
      transition('* <=> *', [
        animate('5000ms')
      ])
    ]),
    trigger('backchange3', [
      state('*', style({
        opacity: 0
      })),
      state('1', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 1
      })),
      transition('* <=> *', [
        animate('5000ms')
      ])
    ]),
    trigger('backchange4', [
      state('*', style({
        opacity: 0
      })),
      state('1', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 1
      })),
      state('2', style({
        opacity: 1
      })),
      state('4', style({
        opacity: 1
      })),

      transition('* <=> *', [
        animate('5000ms')
      ])
    ]),
     
    trigger('highlight', [
      transition(':enter',[
        query('div', style({ opacity:0 })),

        query('div', 
          animate('2000ms ease-out', style({ opacity: 0 }))),

        query('div',
          stagger('2000ms', [
            animate('5000ms', style({ opacity: 1 }))
          ]))

      ]),
      transition(':leave',[
        style({ opacity: 1 }),
        animate('1000ms', style({ opacity: 0 }))
      ]),
    ]),

    trigger('highlight2', [
      transition(':enter',[
        query('div', style({ opacity:0 })),

        query('div', 
          animate('1300ms ease-out', style({ opacity: 0 }))),

        query('div',
          stagger('1000ms', [
            animate('3000ms', style({ opacity: 1 }))
          ]))

      ]),
      transition(':leave',[
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0 }))
      ]),
    ]),


  ]
})
export class TourComponent implements OnInit {

  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;

  myStyle2: object = {};
  myParams2: object = {};

  myStyle3: object = {};
  myParams3: object = {};

  Seite: number = 1;
  Step: number = 0;
  Backg: number = this.Seite;
  weiter: boolean = false;
  beginne: boolean = true;
  wait: number;

  sicher: boolean = false;

  sprache: number = 0.7;

  music = new Howl({
    src: ['../../assets/audio/Borderless.mp3'],
    preload: true,
    volume: 0.05,
    autoplay: false,
    loop: true,
  })

  nature = new Howl({
    src: ['../../assets/audio/nature.wav'],
    preload: true,
    volume: 0.2,
    autoplay: true,
    loop: true,
  })

  click = new Howl({
    src: ['../../assets/audio/click.wav'],
    preload: true,
    volume: 0.2,
    autoplay: false,
    loop: false,
  })

  ersteSeite = new Howl({
    src: ['../../assets/audio/Seite1.wav'],
    preload: true,
    volume: 0.5,
    autoplay: false,
    loop: false,
  })

  zweiteSeite = new Howl({
    src: ['../../assets/audio/Seite2.wav'],
    preload: true,
    volume: this.sprache,
    autoplay: false,
    loop: false,
  })

  dritteSeite = new Howl({
    src: ['../../assets/audio/Seite3.wav'],
    preload: true,
    volume: this.sprache,
    autoplay: false,
    loop: false,
  })

  vierteSeite = new Howl({
    src: ['../../assets/audio/Seite4.wav'],
    preload: true,
    volume: this.sprache,
    autoplay: false,
    loop: false,
  })

constructor() { 
  
}

ngOnInit() {
  this.checke();

  if(this.Seite>1&&this.Seite<6){
    this.music.play();
  }    
  


  this.myStyle = {
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'z-index': -1,
    'top': 0,
    'left': '0',
    'right': 0,
    'bottom': 0,
    
};


this.myParams = { //blue
  particles: {
    number: { value: 1, density: { enable: false, value_area: 30000 } },
    color: { value: "#ff9d00" },
    shape: {
      type: "image",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "../../assets/img/first/sternschnuppe.svg", width: 100, height: 100 }
    },
    opacity: {
      value: 1,
      random: false,
      anim: { enable: true, speed: 1, opacity_min: 0.5, sync: false }
    },
    size: {
      value: 5,
      random: true,
      anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: "#ffffff",
      opacity: 0.4,
      width: 1
    },
    move: {
      enable: true,
      speed: 40,
      direction: "bottom",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 1200 }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: { enable: false, mode: "repulse" },
      onclick: { enable: false, mode: "remove" },
      resize: true
    },
    modes: {
      grab: { distance: 400, line_linked: { opacity: 1 } },
      bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
      repulse: { distance: 120, duration: 0.3 },
      push: { particles_nb: 2 },
      remove: { particles_nb: 20 }
    }
  },
  } 


  this.myStyle2 = {
    'position': 'fixed',
    'width': '100%',
    'height': '100%',
    'z-index': -1, 
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
};

  this.myParams2 = { //orange
    particles: {
      number: { value: 30, density: { enable: true, value_area: 800 } },
      color: { value: "#ffffff" },
      shape: {
        type: "circle",
        stroke: { width: 0, color: "#000000" },
        polygon: { nb_sides: 5 },
        image: { src: "img/github.svg", width: 100, height: 100 }
      },
      opacity: {
        value: 1,
        random: true,
        anim: { enable: true, speed: 1, opacity_min: 0.5, sync: false }
      },
      size: {
        value: 6,
        random: true,
        anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
      },
      line_linked: {
        enable: false,
        distance: 48.10236182596568,
        color: "#ffffff",
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        speed: 4,
        direction: "bottom-left",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: { enable: false, rotateX: 600, rotateY: 1200 }
      }
    },
    interactivity: {
      detect_on: "window",
      events: {
        onhover: { enable: false, mode: "repulse" },
        onclick: { enable: false, mode: "push" },
        resize: true
      },
      modes: {
        grab: { distance: 400, line_linked: { opacity: 1 } },
        bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
        repulse: { distance: 97.44926547616141, duration: 0.4 },
        push: { particles_nb: 4 },
        remove: { particles_nb: 2 }
      }
    },
    } 

    this.myStyle3 = {
      'position': 'fixed',
      'width': '100%',
      'height': '100%',
      'z-index': -1, 
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
  };

    this.myParams3 = { //dark blue
      particles: {
        number: { value: 50, density: { enable: true, value_area: 800 } },
        color: { value: "#ffffff" },
        shape: {
          type: "circle",
          stroke: { width: 0, color: "#000000" },
          polygon: { nb_sides: 5 },
          image: { src: "img/github.svg", width: 100, height: 100 }
        },
        opacity: {
          value: 1,
          random: true,
          anim: { enable: true, speed: 1, opacity_min: 0.5, sync: false }
        },
        size: {
          value: 2,
          random: true,
          anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
        },
        line_linked: {
          enable: false,
          distance: 48.10236182596568,
          color: "#ffffff",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: { enable: false, rotateX: 600, rotateY: 1200 }
        }
      },
      interactivity: {
        detect_on: "window",
        events: {
          onhover: { enable: true, mode: "repulse" },
          onclick: { enable: false, mode: "push" },
          resize: true
        },
        modes: {
          grab: { distance: 400, line_linked: { opacity: 1 } },
          bubble: { distance: 400, size: 10, duration: 2, opacity: 8, speed: 3 },
          repulse: { distance: 97.44926547616141, duration: 0.4 },
          push: { particles_nb: 4 },
          remove: { particles_nb: 2 }
        }
      },
      }
    }

    Beginne(){
      if(this.beginne==true){
      // this.music.fade(0.2, 0, 3000);
       //versteckt den Mauszeiger nach x ms inaktivität 
  
        // $(document).ready(function() {  
        //   var idleMouseTimer;
        //   var forceMouseHide = false;
        //   $("body").css('cursor', 'none');
        //   $("#wrapper").mousemove(function(ev) {
        //     if(!forceMouseHide) {
        //       $("body").css('cursor', '');
        //       clearTimeout(idleMouseTimer);
        //       idleMouseTimer = setTimeout(function() {
        //         $("body").css('cursor', 'none');
        //         forceMouseHide = true;
        //         setTimeout(function() {
        //           forceMouseHide = false;
        //         }, 200);
        //       }, 1000);
        //     }
        //   }); 
        // });
        
  
        // this.nature.fade(0, 1.5, 4000);
        this.Seite = this.Seite+0.5;
        console.log("0.5");
        setTimeout(() => {  this.Seite=this.Seite+0.5; this.Backg = this.Seite; this.music.play();
          setTimeout(() => {
             this.ersteSeite.play();
          }, 2300);
         
          
        }, 2000);
  
      this.beginne=false;
    }
  
      
  
      
  
      
      
    }
  
    naechsteSeite(){
      this.click.play();
      this.weiter = false;
      this.Seite = this.Seite+0.5;
      console.log(this.Seite);
      setTimeout(() => {  
        this.Seite=this.Seite+0.5; 
        setTimeout(() =>{
          if(this.Seite==5){
            this.wait=50;
          } else { this.wait = 2000}
          switch(this.Seite) {
            case 3:
              this.ersteSeite.stop();
              this.zweiteSeite.play();          
              break;
            case 4:
              this.zweiteSeite.stop();
              this.dritteSeite.play();
              break;
            case 5:
              this.dritteSeite.stop();
              this.vierteSeite.play();
              break;
          }
          this.showAllElements();
        }, this.wait);
        
  
        this.istletzteSeite();
      }, 2000);
    }
  
    checke(){
      this.istletzteSeite();
    }
  
      ueberspringen(){
        this.sicher=!this.sicher;
      }
  
      showAllElements(){
        setTimeout(() => {  
          if(this.weiter==false){
            this.weiter = true;
          }
        }, 15000);         
      }
  
      istletzteSeite(){
        if(this.Seite==5){
  
          setTimeout(() => { 
            this.Seite=this.Seite+0.3;
            setTimeout(() => { 
              this.Seite=this.Seite+0.3;
            }, 1000);
           }, 10000);
        }
      }
  }
  
  
  
  
